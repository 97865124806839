import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyAdminService} from '../../services/company-admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../../club/services/admin.service';
import {IAccountData} from "../../../../shared/services/user/interfaces/account-data";

@Component({
    selector: 'app-edituser',
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit, OnDestroy {

    public userData: IAccountData = {
        active: true,
        deleted: false,
        displayName: "",
        email: "",
        firmId: 0,
        language: "",
        licence: "",
        privileges: [],
        role: '',
        userName: ""
    };
    public userDataCreate: IAccountData = {
        active: true,
        deleted: false,
        displayName: "",
        email: "",
        firmId: 0,
        language: "",
        licence: "",
        privileges: [],
        role: '',
        userName: ""
    };
    public userID_params: any;
    public firmID: number;
    public create: boolean;
    public role: string;

    constructor(public companyAdminService: CompanyAdminService,
                private route: ActivatedRoute,
                private router: Router,
                public adminService: AdminService) {
        this.create = false;
        this.role = '';
    }

    ngOnInit() {
        this.companyAdminService.newUserData$.subscribe(user => {
            if (user) {
                this.userData = user;
            }
        })

        this.firmID = 0;
        this.route.params.subscribe(
            (params) => this.userID_params = params['id']
        );

        this.route.params.subscribe(
            (params) => this.firmID = params['firmid']
        );
        if (this.userID_params === 'create') {
            this.create = true;
        } else {
            this.create = false;
            this.getUserById(this.userID_params)
        }
    }

    ngOnDestroy() {
        this.companyAdminService.newUserData$.next(undefined);
    }


    public getUserById(id: number) {
        this.companyAdminService.getUserById(id).subscribe(
            (response) => {
                this.userData = response;
            }
        );
    }

    public reset() {
        this.companyAdminService.updateFirmData();
        this.router.navigate(['company-admin/user']);
    }

    public update(user: any, goBack: boolean = false) {
        this.userData = user;
        this.userData.country = this.companyAdminService.firmData.address.country.iso31661Alpha2;
        if (this.create) {
            this.userDataCreate = this.userData;
            this.userDataCreate.firmId = this.companyAdminService.firmData.firmId;
            this.companyAdminService.createUser(this.userDataCreate, goBack); // should return userid from creation

        } else {
            this.userData.valid = true;
            this.companyAdminService.setUserData(this.userData, this.userID_params, goBack);
        }
    }

    public updateReset(user: any) {
        this.update(user, true);
        this.reset();
    }
}
