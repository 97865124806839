<div class="suppliers">
  <div class="heading blue">
    <span class="clickable" routerLink="/admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable"  routerLink="/admin/suppliers">
        <i class="fa fa-caret-right"></i>
        {{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}
    </span>
    <div class="float-right">
      <span class="clickable" (click)="createSupplier()">
          <i class="fa fa-plus"></i>
          {{'SUPPLIERS.CREATE' | translate}}
      </span>
    </div>
  </div>

  <div class="panel-content center-content white-background">
    <app-default-loading-page *ngIf="loadSupplier"></app-default-loading-page>
    <ng-container *ngIf="suppliers?.length > 0 && !loadSupplier">
      <ng-container *ngIf="userService.allow('SUPPLIER_MANAGEMENT')">

        <div class="club-setting-title">
          <h1>
            <strong>
              {{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}
            </strong>
          </h1>
        </div>
        <div class="ml-2">
          <small><i class="fa fa-star"></i> {{'USER_PROFILE.SUPPLIER_MANAGEMENT_INFO' | translate}} {{'USER_PROFILE.SPECIAL_INFO' | translate}}</small>
        </div>



        <ng-container *ngFor="let type of supplierTypes">
          <br><br>
          <span><strong>{{'USER_PROFILE.' + type | translate}}</strong></span>
          <div class="row supplier-head">
            <div class="col-1"></div>
            <div class="col-2 text-center">Logo</div>
            <div class="col-3">{{'SUPPLIERS.SUPPLIER' | translate}}</div>
            <div class="col-3">{{'SUPPLIERS.CUSTOMERNR' | translate}}</div>
            <div class="col-3 text-center">{{'USER_PROFILE.USER_MANAGEMENT.OPTIONS' | translate}}</div>
          </div>
          <div class="drag-list" cdkDropList [id]="type" [attr.id]="type" [cdkDropListConnectedTo]="(type === 'STANDARD' ? 'SPECIAL' : 'STANDARD')"
               (cdkDropListDropped)="configService.sortList((type === 'STANDARD' ? standardSupp : specializedSupp), $event); isSort = true; swapSuppliers($event, type)"
               [cdkDropListData]="(type === 'STANDARD' ? standardSupp : specializedSupp)"
               *ngIf="hasActiveSupplier()">
            <ng-container *ngFor="let supp of (type === 'STANDARD' ? standardSupp : specializedSupp)">
              <div class="row supplier-list drag-box" cdkDrag [id]="supp.id" *ngIf="supp.active && (type === 'STANDARD' ? supp.specialized === false : supp.specialized === true)">
                <div class="col-1"></div>
                <div class="col-2">
                  <img style="max-height: 40px"
                       class="img-fluid"
                       [supplier]="supp.supplier"
                       [logo-color]="true"
                       title="{{supp.supplierName}}">
                </div>
                <div class="col-3">
                  {{supp.supplierName}}
                </div>
                <div class="col-3 customer-number">
                  {{supp.customerNo}}
                </div>
                <div class="col-3">
                  <div class="center-wrapper">
                    <button class="icon-btn" (click)="navigate(supp)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="icon-btn" (click)="delete(false, supp)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="alert alert-danger"  style="margin-bottom: 0" *ngIf="supp.delete && supp.active">
                <b>{{supp.supplierName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
                <button class="base-btn " (click)="supp.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                <button class="base-btn error pull-right no-top-margin" (click)="delete(true, supp)"> {{'ADMIN_PANEL.DELETE' | translate}} </button>
              </div>
            </ng-container>
          </div>
          <div class="drag-list disabled" *ngIf="hasInactiveSupplier()">
            <ng-container *ngFor="let supp of (type === 'STANDARD' ? standardSupp : specializedSupp)">
              <div class="row supplier-list drag-box" *ngIf="!supp.active && (type === 'STANDARD' ? supp.specialized === false : supp.specialized === true)">
                <div class="col-1"></div>
                <div class="col-2">
                  <img style="max-height: 40px"
                       class="img-fluid"
                       [supplier]="supp.supplier"
                       [logo-color]="true"
                       title="{{supp.supplierName}}">
                </div>
                <div class="col-3">
                  {{supp.supplier}}
                </div>
                <div class="col-3">
                  {{supp.customerNo}}
                </div>
                <div class="col-3">
                  <div class="center-wrapper">
                    <button class="icon-btn" (click)="navigate(supp)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="icon-btn" (click)="delete(false, supp)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="alert alert-danger"  style="margin-bottom: 0" *ngIf="supp.delete && !supp.active">
                <b>{{supp.supplierName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
                <button class="base-btn " (click)="supp.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                <button class="base-btn error pull-right no-top-margin" (click)="delete(true, supp)"> {{'ADMIN_PANEL.DELETE' | translate}} </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <br><br>
        <button class="base-btn success mt-4" *ngIf="isSort" (click)="saveSupplierList()">
          <i class="fa fa-check mr-2"></i>
          <span>{{'ADMIN_PANEL.SAVE_CHANGES' | translate}}</span>
        </button>
      </ng-container>
    </ng-container>
    <app-error-handler *ngIf="errorHandlerService.newError"></app-error-handler>
    <ng-container *ngIf="suppliers?.length === 0">
      <app-no-results></app-no-results>
    </ng-container>
  </div>
</div>
