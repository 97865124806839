import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {Location} from '@angular/common';
import {UntypedFormGroup} from '@angular/forms';
import {HelperService} from '../../../shared/services/helper.service';
import {IRights} from "../../../shared/interfaces/IRights";
import {PrivilegesEnum, RolesEnum} from "../../../shared/privilegesLicencesRoles.enum";
import {finalize} from "rxjs/operators";
import {IAccountData} from "../../../shared/services/user/interfaces/account-data";

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html'
})
export class UserDataComponent implements OnInit, OnDestroy {

    @Input() userData: IAccountData = <IAccountData>{};
    @Input() firmData: any;
    @Output() public _back: EventEmitter<any> = new EventEmitter();
    @Output() public _update: EventEmitter<any> = new EventEmitter();
    @Output() public _updateReset: EventEmitter<any> = new EventEmitter();
    public states: any;
    public rights: IRights = <IRights>{};
    public loading: boolean = false;
    public readonly privilegeIsAdminPrivilegDefaultsetted: string[] = [PrivilegesEnum.FIRM_STATISTIC, PrivilegesEnum.USER_MANAGEMENT, PrivilegesEnum.ORDER_HISTORY, PrivilegesEnum.SUPPLIER_MANAGEMENT, PrivilegesEnum.SETTINGS_MANAGEMENT, PrivilegesEnum.ASSORTMENT_PRIORITISATION, PrivilegesEnum.FIRM_STATISTIC];

    userForm = new UntypedFormGroup({});

    constructor(public errorHandlerService: ErrorHandlerService,
                public adminService: AdminService,
                private helperService: HelperService,
                public userService: UserService,
                private location: Location) {

        if (this.adminService && this.adminService.club && this.adminService.club.clubId && this.location.path().includes('/admin/')) {
            this.userService.getLocales(this.adminService.club.clubId).subscribe(
                (response) => {
                    this.states = response;
                    if (this.userData && this.userData.language) {
                        if (!(this.states.indexOf(this.userData.language) > -1)) {
                            this.userData.language = this.states[0].language;
                        }
                    } else {
                        this.userData.language = this.states[0].language;
                    }
                }
            );
        } else {
            this.userService.getLocales().subscribe(
                (response) => {
                    this.states = response;
                    if (this.userData && this.userData.language) {
                        if (this.states.indexOf(this.userData.language) >1) {
                            this.userData.language = this.states[0].language;
                        }
                    } else {
                        this.userData.language = this.states[0].language;
                    }
                }
            );
        }
    }

    ngOnInit(): void {
        if (this.userData && (!this.userData.language || this.userData.language.length === 0)) {
            if (this.userService && this.userService.accountData) {
                this.userData.language = this.userService.accountData.language;
            }
        }
        this.loading = true;
        this.adminService.getPossibleLoginRightsRest(this.firmData?.firmId).pipe(finalize(() => this.loading = false)).subscribe((rights) => {
            rights.privileges = this.sortPrivileges(rights.privileges);
            this.rights = rights;
        });

        //Preset Roles, Privileges and Licence BEGIN
        if (!this.userData?.privileges?.length) {
            this.userData = {
                ...this.userData,
                privileges: []
            };
        }
        if (!this.userData?.role) {
            this.userData.role = RolesEnum.ROLE_USER;
        }
        if (!this.userData?.licence) {
            this.userData.licence = this.firmData?.licence;
        }
        //Preset Roles, Privileges and Licence END
    }

    ngOnDestroy(): void {
        this.errorHandlerService.newError = false;
        this.errorHandlerService.hasError = false;
    }

    public reset() {
        this._back.emit();
    }

    public update() {
        this._update.emit(this.userData);
    }

    public updateReset() {
        this._updateReset.emit(this.userData);
    }

    public isHigherRoleThanOwn(role: string): boolean {
        const roleHierarchy = {'ROLE_USER': 1, 'ROLE_FIRM_ADMIN': 2, 'ROLE_ADMIN': 3, 'ROLE_GLOBAL_ADMIN': 4};
        return roleHierarchy[role] > roleHierarchy[this.userService.accountData.role];
    }


    public sortPrivileges(privileges: string[]): string[] {
        const isAdminPrivileg = (privilege: string) => this.privilegeIsAdminPrivilegDefaultsetted.includes(privilege);
        return privileges.sort((a, b) => {
            if (isAdminPrivileg(a) && !isAdminPrivileg(b)) {
                return 1;
            }
            if (!isAdminPrivileg(a) && isAdminPrivileg(b)) {
                return -1;
            }
            return 0;
        });
    }


    public deleteLogin(userData: any, _delete: boolean, right: string) {
        switch (right) {
            case ('GLOBAL-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.deleteUser(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('CLUB-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserClubAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('FIRM-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserFirmAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
        }
    }


    public toggleLanguage(event) {
        const language = event.target.value;
        this.userData.language = language;
    }

    public isGlobalAdmin() {
        return this.userService.allow('GLOBAL-ADMIN');
    }

    public isFirmAdmin() {
        return this.userService.allow('FIRM-ADMIN');
    }

    public isClubAdmin() {
        return this.userService.allow('CLUB-ADMIN');
    }

    public toggleSelection(type: string, newValue: string) {
        switch (type) {
            case 'ROLE':
                this.userData.role = newValue;
                if (newValue === RolesEnum.ROLE_FIRM_ADMIN || RolesEnum.ROLE_ADMIN) {
                    this.userData.privileges = this.rights.privileges;
                }
                if (newValue === RolesEnum.ROLE_USER) {
                    this.userData.privileges = [];
                }
                break;
            case 'LICENCE':
                this.userData.licence = newValue;
                break;
            case 'PRIVILEGE':
                if (this.userData.privileges?.find((privilege: string) => privilege === newValue)) {
                    this.userData.privileges = this.userData.privileges.filter((privilege: string) => privilege !== newValue);
                } else {
                    this.userData.privileges.push(newValue);
                }
                break;
        }
    }
}
