import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {HelperService} from "../../../services/helper.service";

@Injectable()
export class ErrorHandlerService {

    public errorCode: number;
    public errorText: string;
    public newError: boolean;
    public hasError: boolean;

    constructor(private translateService: TranslateService, private helperService: HelperService) {
        this.errorCode = 0;
        this.newError = false;
        this.errorText = '';
    }

    public throwStringError(errorString: string, errorCode: number) {
        this.errorText = errorString;
        this.newError = true;
        this.errorCode = errorCode;
    }

    public throwError(errorCode: number, showToast: boolean = false) {
        this.errorCode = errorCode;
        this.newError = true;
        const errorMessages = {
            400: 'ERROR.BADREQUEST',
            401: 'ERROR.UNAUTHORIZED',
            403: 'ERROR.FORBIDDEN',
            404: 'ERROR.NOTFOUND',
            409: 'ERROR.CONFLICT',
            default: 'ERROR.DEFAULT'
        };
        const errorMessageKey = errorMessages[errorCode] || errorMessages.default;
        this.translateService.get(errorMessageKey).subscribe((msg: string) => {
            this.errorText = msg;
            if (showToast) {
                this.helperService.showNotification(this.errorText, 'error');
            }
        });
    }

}
