<div class="admin">
  <div class="content sidebar-opener-wrapper clean">
    <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
      <ul>
        <li (click)="showNavigation()" *ngIf="userService?.getNavigation() === 'right'">
          {{'CLUB_SETTINGS.TABLET_NAVIGATION' | translate}}
        </li>
        <li (click)="showDetails()" *ngIf="userService?.getNavigation() === 'left'">
          {{'CLUB_SETTINGS.TABLET_DETAILS' | translate}}
        </li>
        <li (click)="userService?.toggleNavigation()">
          <i class="fa fa-chevron-right"></i>
        </li>
      </ul>
    </div>
    <div class="left-panel no-float" [ngClass]="userService?.getNavigation()">
      <div class="panel-content left-sidebar">
          <ul class="custom-tree">

            <li class="list" *ngIf="!adminService.clubs">
              <div class="clickable">
                <i class="folder fa fa-circle" routerLink="/admin" routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin">{{ 'CLUB_ADMIN.SEARCH' | translate }}</span>
              <ul class="custom-tree">
                <li class="list"  *ngIf="adminService.selectedFirm?.firmId > 0">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/{{adminService.selectedFirm?.firmId}}">{{ adminService.selectedFirm?.name }}</span>
                  <ul class="custom-tree">
                    <li class="list" *ngIf="userService.allow('USER_MANAGEMENT')">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/firm/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/firm/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.MANAGE_USERS' | translate}}</span>
                    </li>
                    <li class="list" *ngIf="userService.allow('SUPPLIER_MANAGEMENT')">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/suppliers/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/suppliers/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/tools" (click)="configService.externalToolAsAdmin = true" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/tools" (click)="configService.externalToolAsAdmin = true">{{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.ADDITIONAL_SETTINGS' | translate}}</span>
                      <span routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}" *ngIf="companyAdminService.hasChanges">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable">
                        <i class="folder fa fa-circle" routerLink="/admin/firm/log/{{adminService.selectedFirm?.firmId}}"></i>
                      </div>
                      <span routerLink="/admin/firm/log/{{adminService.selectedFirm?.firmId}}">{{'ADMIN.LOG.TITLE' | translate}}</span>
                    </li>
                    <li class="list"
                        *ngIf="!adminService.clubs">
                        <div class="clickable">
                          <i class="folder fa fa-circle" routerLink="/admin/firm/assortment"
                             routerLinkActive="open"></i>
                        </div>
                        <span routerLink="/admin/firm/assortment">{{ 'CLUB_ADMIN.ASSORTMENT' | translate }}</span>
                        <ul class="custom-tree">
                          <li class="list">
                            <div class="clickable">
                              <i class="folder fa fa-circle" routerLink="/admin/firm/assortment/supplier"
                                 routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/admin/firm/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
                          </li>
                          <li class="list">
                            <div class="clickable">
                              <i class="folder fa fa-circle" routerLink="/admin/firm/assortment/article"
                                 routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/admin/firm/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
                          </li>
                        </ul>
                    </li>
                  </ul>
                </li>
                <li class="list">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/create" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/create">{{ 'CLUB_ADMIN.CREATE' | translate }}</span>
                </li>
              </ul>
            </li>

            <!--Vorlagen-->
            <li class="list" *ngIf="!adminService.clubs">
              <div class="clickable" >
                <i class="folder fa fa-circle" routerLink="/admin/template" routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin/template">{{'TEMPLATE.TITLE' | translate}}</span>
              <ul class="custom-tree">
                <li class="list">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/template/all" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/template/all">{{'TEMPLATE.ALL' | translate}}</span>
                </li>
                <li class="list">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/template/article" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/template/article">{{'TEMPLATE.ARTICLE' | translate}}</span>
                </li>
                <li class="list">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/template/work" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/template/work">{{'TEMPLATE.WORK' | translate}}</span>
                </li>
                <li class="list">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/template/text" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/template/text">{{'TEMPLATE.TEXT' | translate}}</span>
                </li>
              </ul>
            </li>


            <!--Partner-->
            <li class="list" *ngIf="!adminService.clubs">
              <div class="clickable">
                <i class="folder fa fa-circle" routerLink="/admin/partner" routerLinkActive="open"></i>
              </div>
              <span *ngIf="!partnerService.getPartnerDisplayName()" routerLink="/admin/partner">{{ 'CLUB_ADMIN.PARTNER' | translate }}</span>
              <span *ngIf="partnerService.getPartnerDisplayName()" routerLink="/admin/partner">{{partnerService.getPartnerDisplayName()}}</span>
            </li>
            <!--Benachrichtigungen-->
            <li class="list" *ngIf="!adminService.clubs">
              <div class="clickable">
                <i class="folder fa fa-circle" routerLink="/admin/notifications" routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin/notifications">{{ 'CLUB_ADMIN.NOTIFICATIONS' | translate }}</span>
            </li>

            <li class="list" *ngIf="!adminService.clubs && userService.allow('GLOBAL-ADMIN')">
              <div class="clickable">
                <i class="folder fa fa-circle" routerLink="/admin/external-tools" routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin/external-tools">{{ 'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate }}</span>
            </li>

            <!--Einstellungen-->
            <li class="list" *ngIf="!adminService.clubs && userService.allow('SETTINGS_MANAGEMENT')">
              <div class="clickable" (click)="resetDynamicSettingWrapper()">
                <i class="folder fa fa-circle" routerLink="/admin/config" routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin/config" (click)="resetDynamicSettingWrapper()">{{ 'CLUB_ADMIN.CONFIG' | translate }}</span>
              <ul class="custom-tree">
                <li class="list" *ngFor="let setting of configService?.clubConfig?.settings">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.' + setting.first | translate}}</span>
                  <span routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings(setting.first)">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                </li>
                <li class="list" *ngIf="userService.allow('SUPPLIER_MANAGEMENT')">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.SUPPLIER' | translate}}</span>
                  <span routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings('SUPPLIER')">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                </li>
                <li class="list" *ngIf="userService.allow('GLOBAL-ADMIN') || userService.allow('CLUB-ADMIN')">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/config/COUNTRY" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/config/COUNTRY" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.COUNTRY' | translate}}</span>
                  <span routerLink="/admin/config/COUNTRY" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings('COUNTRY')">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                </li>
              </ul>
            </li>
            <li class="list"
                *ngIf="!adminService.clubs">
              <ng-container  *ngIf="userService.allow('ASSORTMENT_PRIORITISATION')">
              <div class="clickable">
                <i class="folder fa fa-circle" routerLink="/admin/assortment"
                   routerLinkActive="open"></i>
              </div>
              <span routerLink="/admin/assortment">{{ 'CLUB_ADMIN.ASSORTMENT' | translate }}</span>
              <ul class="custom-tree">
                <li class="list">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/assortment/supplier"
                       routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
                </li>
                <li class="list">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/assortment/article"
                       routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
                </li>
              </ul>
              </ng-container>
            </li>

            <li class="list" *ngFor="let club of adminService.clubs" (click)="adminService.changeClub(club)">
            <div class="clickable">
              <i class="folder fa fa-circle" routerLink="/admin" [ngClass]="{'open': club.clubId === adminService?.club?.clubId}"></i>
            </div>
            <span routerLink="/admin">{{club.name}}</span>
              <ul class="custom-tree">
                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin">{{ 'CLUB_ADMIN.SEARCH' | translate }}</span>
                  <ul class="custom-tree">
                    <li class="list"  *ngIf="adminService.selectedFirm?.firmId > 0">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/{{adminService.selectedFirm?.firmId}}">{{ adminService.selectedFirm?.name }}</span>
                      <ul class="custom-tree">
                        <li class="list" *ngIf="userService.allow('USER_MANAGEMENT')">
                          <div class="clickable" >
                            <i class="folder fa fa-circle" routerLink="/admin/firm/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                          </div>
                          <span routerLink="/admin/firm/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.MANAGE_USERS' | translate}}</span>
                        </li>
                        <li class="list">
                          <div class="clickable" >
                            <i class="folder fa fa-circle" routerLink="/admin/suppliers/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                          </div>
                          <span routerLink="/admin/suppliers/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}</span>
                        </li>
                        <li class="list">
                          <div class="clickable" >
                            <i class="folder fa fa-circle" routerLink="/admin/tools" routerLinkActive="open" (click)="configService.externalToolAsAdmin = true"></i>
                          </div>
                          <span routerLink="/admin/tools" (click)="configService.externalToolAsAdmin = true">{{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}</span>
                        </li>
                        <li class="list">
                          <div class="clickable" >
                            <i class="folder fa fa-circle" routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                          </div>
                          <span routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.ADDITIONAL_SETTINGS' | translate}}</span>
                          <span routerLink="/admin/firm/advanced/{{adminService.selectedFirm?.firmId}}" *ngIf="companyAdminService.hasChanges">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                        </li>
                          <li class="list">
                              <div class="clickable" >
                                  <i class="folder fa fa-circle" routerLink="/admin/firm/supplier-blacklist/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                              </div>
                              <span routerLink="/admin/firm/supplier-blacklist/{{adminService.selectedFirm?.firmId}}">{{'USER_PROFILE.SUPPLIER_BLACKLIST' | translate}}</span>
                              <span routerLink="/admin/firm/supplier-blacklist/{{adminService.selectedFirm?.firmId}}" *ngIf="companyAdminService.hasChangesBlacklist">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                          </li>
                        <li class="list">
                          <div class="clickable">
                            <i class="folder fa fa-circle" routerLink="/admin/firm/log/{{adminService.selectedFirm?.firmId}}" routerLinkActive="open"></i>
                          </div>
                          <span routerLink="/admin/firm/log/{{adminService.selectedFirm?.firmId}}">{{'ADMIN.LOG.TITLE' | translate}}</span>
                        </li>
                        <li class="list" *ngIf="userService.getActiveForName('EXTENDED_SETTINGS','FIRM_ASSORTMENT_PRIORITIZATION')">
                          <div class="clickable">
                            <i class="folder fa fa-circle" routerLink="/admin/firm/assortment"
                               routerLinkActive="open"></i>
                          </div>
                          <span routerLink="/admin/firm/assortment" [ngClass]="{'disabled-list-element': userService.getActiveForName('EXTENDED_SETTINGS','AUTOMATIC_ASSORTMENT_PRIORITIZATION')}">{{ 'CLUB_ADMIN.ASSORTMENT' | translate }}</span>
                          <ul class="custom-tree">
                            <li class="list">
                              <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/admin/firm/assortment/supplier"
                                   routerLinkActive="open"></i>
                              </div>
                              <span routerLink="/admin/firm/assortment/supplier"  [ngClass]="{'disabled-list-element': userService.getActiveForName('EXTENDED_SETTINGS','AUTOMATIC_ASSORTMENT_PRIORITIZATION')}">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
                            </li>
                            <li class="list">
                              <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/admin/firm/assortment/article"
                                   routerLinkActive="open"></i>
                              </div>
                              <span routerLink="/admin/firm/assortment/article"  [ngClass]="{'disabled-list-element': userService.getActiveForName('EXTENDED_SETTINGS','AUTOMATIC_ASSORTMENT_PRIORITIZATION')}">{{'ASSORTMENT.ARTICLE' | translate}}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/create" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/create">{{ 'CLUB_ADMIN.CREATE' | translate }}</span>
                    </li>
                  </ul>
                </li>

                <!--Vorlagen-->
                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId">
                  <div class="clickable" >
                    <i class="folder fa fa-circle" routerLink="/admin/template" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/template">{{'TEMPLATE.TITLE' | translate}}</span>
                  <ul class="custom-tree">
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/template/all" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/template/all">{{'TEMPLATE.ALL' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/template/article" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/template/article">{{'TEMPLATE.ARTICLE' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/template/work" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/template/work">{{'TEMPLATE.WORK' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/admin/template/text" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/template/text">{{'TEMPLATE.TEXT' | translate}}</span>
                    </li>
                  </ul>
                </li>

                <!--Partner-->
                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/partner" routerLinkActive="open"></i>
                  </div>
                  <span *ngIf="!partnerService.getPartnerDisplayName()" routerLink="/admin/partner">{{ 'CLUB_ADMIN.PARTNER' | translate }}</span>
                  <span *ngIf="partnerService.getPartnerDisplayName()" routerLink="/admin/partner">{{partnerService.getPartnerDisplayName()}}</span>
                </li>
                <!--Benachrichtigungen-->
                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/notifications" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/notifications">{{ 'CLUB_ADMIN.NOTIFICATIONS' | translate }}</span>
                </li>

                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId && userService.allow('GLOBAL-ADMIN')">
                  <div class="clickable">
                    <i class="folder fa fa-circle" routerLink="/admin/external-tools" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/external-tools">{{ 'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate }}</span>
                </li>

                <!--Einstellungen-->
                <li class="list" *ngIf="club.clubId === adminService?.club?.clubId && userService.allow('SETTINGS_MANAGEMENT')">
                  <div class="clickable" (click)="resetDynamicSettingWrapper()">
                    <i class="folder fa fa-circle" routerLink="/admin/config" routerLinkActive="open"></i>
                  </div>
                  <span routerLink="/admin/config" (click)="resetDynamicSettingWrapper()">{{ 'CLUB_ADMIN.CONFIG' | translate }}</span>
                  <ul class="custom-tree">
                    <li class="list" *ngFor="let setting of configService?.clubConfig?.settings">
                      <div class="clickable">
                        <i class="folder fa fa-circle" routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.' + setting.first | translate}}</span>
                      <span routerLink="/admin/config/{{setting.first}}" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings(setting.first)">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                    </li>
                    <li class="list">
                      <div class="clickable">
                        <i class="folder fa fa-circle" routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.SUPPLIER' | translate}}</span>
                      <span routerLink="/admin/config/SUPPLIER" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings('SUPPLIER')">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                    </li>
                    <li class="list" *ngIf="userService.allow('GLOBAL-ADMIN') || userService.allow('CLUB-ADMIN')">
                      <div class="clickable">
                        <i class="folder fa fa-circle" routerLink="/admin/config/COUNTRY" routerLinkActive="open"></i>
                      </div>
                      <span routerLink="/admin/config/COUNTRY" (click)="resetDynamicSettingWrapper()">{{'CLUB_ADMIN.SETTING_NAME.COUNTRY' | translate}}</span>
                      <span routerLink="/admin/config/COUNTRY" (click)="resetDynamicSettingWrapper()" *ngIf="configService.hasUnsavedSettings('COUNTRY')">{{'ADMIN_PANEL.UNSAVED' | translate}}</span>
                              </ul>
                            </li>
                            <li class="list"
                                *ngIf="club.clubId === adminService?.club?.clubId && userService.allow('ASSORTMENT_PRIORITISATION')">
                                <div class="clickable">
                                    <i class="folder fa fa-circle" routerLink="/admin/assortment"
                                       routerLinkActive="open"></i>
                                </div>
                                <span routerLink="/admin/assortment">{{ 'CLUB_ADMIN.ASSORTMENT' | translate }}</span>
                                <ul class="custom-tree">
                                    <li class="list">
                                        <div class="clickable">
                                            <i class="folder fa fa-circle" routerLink="/admin/assortment/supplier"
                                               routerLinkActive="open"></i>
                                        </div>
                                        <span routerLink="/admin/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
                                    </li>
                                    <li class="list">
                                        <div class="clickable">
                                            <i class="folder fa fa-circle" routerLink="/admin/assortment/article"
                                               routerLinkActive="open"></i>
                                        </div>
                                        <span routerLink="/admin/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
                                    </li>
                                </ul>

                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div [@fadeSmallAnimation]="getRouteAnimation(route)" class="center-panel no-float left" [ngClass]="userService?.getNavigation()">
            <router-outlet #route="outlet"></router-outlet>
        </div>

    <div class="right-panel no-float" [ngClass]="userService?.getNavigation()">
      <div class="panel-content right-sidebar">
        <div class="inner-content">

          <ng-container *ngIf="location?.path()?.includes('/assortment/')">
            <app-new-assortment></app-new-assortment>
          </ng-container>

          <app-collapsible-panel [panelTitle]="'Clubkey'" *ngIf="configService?.clubConfig?.clubKey && (location?.path().endsWith('/admin/config') || location?.path().endsWith('/admin'))">
            <table>
              <tbody>
              <tr>
                <td><strong>Club Key: </strong></td>
                <td>{{configService.clubConfig.clubKey}}</td>
              </tr>
              </tbody>
            </table>
          </app-collapsible-panel>

          <ng-container *ngIf="configService.selectedSetting && (location?.path().indexOf('/admin/config') > -1)">
            <div class="club-setting-title">
              <h1>
                <strong>
                  {{'CLUB_SETTINGS.' + configService.selectedSettingName + '.' + configService.selectedSetting.name | translate}}
                </strong>
              </h1>
            </div>

            <div>
              <div *ngIf="configService.selectedSetting.className.includes('NumberValueSettingDTO')">
                <app-material-form-input [label]="'CLUB_SETTINGS.VALUE' | translate"
                                         [text]="configService.selectedSetting.value"
                                         [type]="'number'"
                                         [disabled]="configService.selectedSetting.detailsReadOnly"
                                         (change)="changeValue($event.target.value)">
                </app-material-form-input>
              </div>
              <div *ngIf="!(configService.selectedSetting.className.includes('NumberValueSettingDTO'))">
              <app-dropdown [items]="configService.selectedSetting.selection"
                            [preSelectedItem]="configService.selectedSetting.selected"
                            [dropdownDisabled]="configService.selectedSetting.detailsReadOnly"
                            (onItemSelect)="changeSelected($event)"
                            [translationPrefix]="'CLUB_SETTINGS.' + configService.selectedSettingName">
              </app-dropdown>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="location?.path()?.endsWith('/config/car-selection')">
            <app-collapsible-panel *ngFor="let config of getCollapsibleConfigs()" [folded]="!config.active" [panelTitle]="'CLUB_ADMIN.SETTINGS.' + config.name | translate">
              <div class="right-panel-setting">
                <app-checkbox [labelText]="'ADMIN_PANEL.ACTIVE'| translate" (onChange)="config.active = $event; configService.addUnsavedSetting('CAR_SELECTION')" [isChecked]="config.active"></app-checkbox>
                <div class="custom-dropdown clickable" *ngIf="config.active">
                <select [(ngModel)]="config.selected" (ngModelChange)="configService.addUnsavedSetting('CAR_SELECTION')">
                  <option [value]="selection" *ngFor="let selection of config.selection">
                    <span *ngIf="selection !== 'PARTNER'">{{'CLUB_SETTINGS.NAVIGATION.' + selection | translate}}</span>
                    <span *ngIf="selection === 'PARTNER'">{{partnerService.getPartnerDisplayName()}}</span>
                  </option>
                </select>
                </div>
              </div>
            </app-collapsible-panel>
          </ng-container>
          <app-external-tools-details *ngIf="location?.path()?.endsWith('/admin/external-tools') && globalAdminService.selectedExternalTool"></app-external-tools-details>
        </div>
      </div>

    </div>
  </div>
</div>
