<div class="usermanagement">
  <div class="heading blue">
     <span class="clickable"  routerLink="/admin">
          <i class="fa fa-th" aria-hidden="true"></i>
          {{'ADMIN_PANEL.OVERVIEW' | translate}}
      </span>
      <span>
          <i class="fa fa-caret-right"></i>
          {{ 'USER_PROFILE.USER_MANAGEMENTS' | translate }}
      </span>
      <div class="float-right" *ngIf="!((userService.authMethode$ | async) === 'N4SSO')">
          <span class="clickable" (click)="createUser()">
              <i class="fa fa-user-plus"></i>
              {{'USER_PROFILE.CREATEUSER' | translate}}
          </span>
      </div>
  </div>
  <div class="panel-content center-content white-background">
    <ng-container *ngIf="adminService.selectedFirm">
      <ng-container *ngIf="userService.allow('USER_MANAGEMENT')">
        <table class="simple-table">
          <thead>
          <tr>
            <th>{{'USER_PROFILE.USER_MANAGEMENT.USERNAME' | translate}}</th>
            <th>{{'USER_PROFILE.USER_MANAGEMENT.EMAIL' | translate }}</th>
            <th width="40%">{{'USER_PROFILE.USER_MANAGEMENT.ROLES' | translate}}</th>
            <th>{{'USER_PROFILE.USER_MANAGEMENT.ACTIVE' | translate}}</th>
            <th colspan="2">{{'USER_PROFILE.USER_MANAGEMENT.OPTIONS' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of adminService.selectedFirm.loginList"  [ngClass]="{'deactivated-user': user.deleted}">
              <td colspan="6" *ngIf="user.delete">
                  <div class="alert alert-danger mb-0">
                      <b>{{user.userName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
                      <button class="base-btn "
                              (click)="user.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                      <button *ngIf="isGlobalAdmin()" class="base-btn error pull-right no-top-margin"
                              (click)="deleteLogin(user, true, 'GLOBAL-ADMIN')">
                          {{'PROMPTS.BUTTONS.DELETE' | translate}}
                      </button>
                      <button *ngIf="isClubAdmin()&& !isGlobalAdmin()"
                              class="base-btn error pull-right no-top-margin"
                              (click)="deleteLogin(user, true, 'CLUB-ADMIN')">
                          {{'PROMPTS.BUTTONS.DELETE' | translate}}
                      </button>
                      <button *ngIf="isFirmAdmin() && !isClubAdmin()"
                              class="base-btn error pull-right no-top-margin"
                              (click)="deleteLogin(user, true, 'FIRM-ADMIN')">
                          {{'PROMPTS.BUTTONS.DELETE' | translate}}
                      </button>
                  </div>
              </td>
            <td *ngIf="!user.delete">{{user.userName}}</td>
            <td *ngIf="!user.delete">{{user.email}}</td>
            <td *ngIf="!user.delete">
              <small><span *ngFor="let role of user.roles; let last = last">{{'ADMIN.ROLES.'+role | translate}}<span
                      *ngIf="!last">, </span> </span></small>
            </td>
            <td *ngIf="!user.delete">
              <ng-container [ngSwitch]="user.active">
                <ng-container *ngSwitchCase="true">
                  <i class="fa fa-check"></i>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <i class="fa fa-close"></i>
                </ng-container>
              </ng-container>
            </td>
            <td *ngIf="!user.delete">
                <ng-container *ngIf="!user.deleted || (user.deleted && isGlobalAdmin()) ">
                    <button class="icon-btn" (click)="navigate(user)"><i class="fa fa-pencil"></i></button>
                </ng-container>
            </td>
              <td *ngIf="!user.delete && isFirmAdmin()">
                  <ng-container *ngIf="!user.deleted || (user.deleted && isGlobalAdmin()) ">
                      <button class="icon-btn" (click)="user.delete = true"><i class="fa fa-trash"></i></button>
                  </ng-container>
              </td>
          </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>
</div>
