import {EventEmitter, Injectable} from '@angular/core';
import {IAccountData} from './interfaces/account-data';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';
import {ICar} from '../../interfaces/DTO/car/car';
import {IPossibleExternalTool} from '../../interfaces/possible-external-tool';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {LicencesEnum, PrivilegesEnum, RolesEnum} from "../../privilegesLicencesRoles.enum";


@Injectable()
export class UserService {
    public navigationHelper: string = 'left';
    public accountData: IAccountData;
    public suppliers: any[];
    public possibleSuppliers: any[] = [];
    public saleEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    public sale: boolean;
    public enableInfiniteScroll: boolean = true;
    public bodygraphicsType: string = 'NONE';
    public accountData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public loginBehavior$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
    public suppliers$: EventEmitter<any> = new EventEmitter<any>();
    public onLogOut$: EventEmitter<any> = new EventEmitter<any>();
    private getSupplierTimeStamp: Date;
    public showRoleError = false;
    public selectedCar: ICar;
    public firmLogo: string = '';
    public activeExternalTools: IPossibleExternalTool[];
    public authMethode$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    public tecrmiError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient,
                private dateTimeAdapter: DateTimeAdapter<any>,
                private toastrService: ToastrService,
                private translate: TranslateService) {
        this.sale = false;

        this.accountData$.subscribe((accountData) => {
            if (accountData) {
                dateTimeAdapter.setLocale(accountData.language);
                this.accountData = accountData;
                this.firmLogo = accountData.firmLogo.firmLogo;
                this.getSuppliers(true);
                this.checkSellinRole();
                this.getExternalTools();
                this.enableInfiniteScroll = this.getActiveForName('EXTENDED_SETTINGS', 'INFINITY_SCROLL');
            }
        });
    }


    public setNavigationDefault() {
        this.navigationHelper = 'left';
    }

    public setNavigationLeft() {
        this.navigationHelper = 'left';
    }

    public setNavigationRight() {
        this.navigationHelper = 'right';
    }

    public getActiveForName(settingName: string, subSettingName: string) {
        const setting = this.getSubSettingForName(settingName, subSettingName);

        if (setting) {
            return setting.active;
        }
    }

    public getSubSettingForName(settingName: string, subSettingName: string) {
        const setting = this.getSettingForName(settingName);
        if (setting) {
            for (const subSetting of setting.second.settings) {
                if (subSetting.name === subSettingName) {
                    return subSetting;
                }
            }
        }
    }

    public getSettingForName(settingName: string) {
        if (this.accountData && this.accountData.clubSettings && this.accountData.clubSettings.settings) {
            for (const i of this.accountData.clubSettings.settings) {
                if (i.first === settingName) {
                    return i;
                }
            }
        }
    }

    public setTecrmiError(status: boolean): void {
        if (this.tecrmiError$.value !== status) {
            this.tecrmiError$.next(status);
        }
    }

    public showAutovistaVin() {
        let autovistaVinAllowChargeable: boolean = false;

        if (this.accountData && this.accountData.privileges) {
            for (const privilege of this.accountData.privileges) {
                if (privilege === 'AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE') {
                        autovistaVinAllowChargeable = true;
                }
            }
        }

        return autovistaVinAllowChargeable;
    }


    public getNavigation() {
        return this.navigationHelper;
    }

    public toggleNavigation() {
        if (this.getNavigation() === 'right') {
            this.setNavigationLeft();
        } else {
            this.setNavigationRight();
        }
    }

    /*
       sale: true -> Verkaufsmodus
       sale: false -> Einkaufsmodus
    */
    public setSale() {
        this.sale = !this.sale;
        this.saleEmitter.emit(this.sale);
    }

    public getCarChangeSettings() {
        return this.accountData.settings['cartCreationBehaviour'].value;
    }

    public checkSellinRole() {
        this.sale = !this.allow('SELLIN');
    }

    public getFirmLogo() {
        return this.firmLogo;
    }

    public getCatalogMode() {
        return (this.accountData && this.accountData.catalogMode);
    }


    private sortSupplierBySortNo(): Promise<boolean> {
        let me = this;
        return new Promise<boolean>(function (resolve: any) {
            me.suppliers.sort(function (a, b) {
                return a.sortNo - b.sortNo;
            });

            resolve(true);
        });
    }

    public getSuppliers(all: boolean = false) {
        if (this.suppliers && !all) {
            return this.suppliers;
        } else {
            if (!this.getSupplierTimeStamp || new Date().getTime() - this.getSupplierTimeStamp.getTime() > 400) {
                this.getSupplierTimeStamp = new Date();
                this.getSuppliersList().subscribe(
                    (suppliersList) => {
                        this.suppliers = suppliersList;

                        this.sortSupplierBySortNo().then(() => {
                            this.suppliers$.emit(suppliersList);
                        });

                        return suppliersList;
                    }
                );
            }
        }
    }

    public getSpecialSuppliers(suppliers: any) {
        if (suppliers) {
            return suppliers.filter(x => x.specialized);
        }
    }

    public getRegularSupplierName(supplier: any) {
        if (supplier && supplier.supplier) {
            if (!supplier.specialized) {
                return supplier.supplier;
            }
        } else if (typeof supplier === 'string') {
            return supplier;
        }
    }


    public getRegularSuppliersName(suppliers: any) {
        if (suppliers) {
            const temp = [];
            for (let i = 0; i < suppliers.length; i++) {
                if (!suppliers[i].specialized) {
                    temp.push({code: suppliers[i].supplier, name: suppliers[i].name})
                }
            }
            return temp;
        }
    }

    public getSuppliersName(suppliers: any) {
        if (suppliers) {
            const temp = [];
            for (let i = 0; i < suppliers.length; i++) {
                temp.push(suppliers[i].supplier)
            }
            return temp;
        }
    }

    public allow(userRoleFor: string) {
        if (this.accountData && this.accountData.role && this.accountData.licence) {
            switch (userRoleFor) {
                case 'LIGHT':
                    if (this.accountData.licence.indexOf(LicencesEnum.ROLE_USER_LIGHT) > -1) return true;
                    if (this.allow('BASIC')) return true;
                    break;
                case 'BASIC':
                    if (this.accountData.licence.indexOf(LicencesEnum.ROLE_USER_PLUS) > -1) return true;
                    if (this.allow('PLUS')) return true;
                    break;
                case 'PLUS':
                    if (this.accountData.licence.indexOf(LicencesEnum.ROLE_USER_PLUS_AW) > -1) return true;
                    if (this.allow('PRO')) return true;
                    break;
                case 'PRO':
                    if (this.accountData.licence.indexOf(LicencesEnum.ROLE_USER_PLUS_AW_TEC) > -1) return true;
                    break;
                case 'MAINTENANCE-DATA':
                    if (this.allow('PLUS')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'WORK-DATA':
                    if (this.allow('PLUS')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'TECHNICAL-DATA':
                    if (this.allow('PRO')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'ARTICLE':
                    if (this.allow('LIGHT')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'TYRE':
                    if (this.allow('LIGHT')) return true;
                    break;
                case 'FIRM-ADMIN':
                    if (this.accountData.role === RolesEnum.ROLE_FIRM_ADMIN) return true;
                    if (this.accountData.role === RolesEnum.ROLE_ADMIN) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'CLUB-ADMIN':
                    if (this.accountData.role === RolesEnum.ROLE_ADMIN) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'GLOBAL-ADMIN':
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'ORDER':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.ROLE_ORDER) > -1) return true;
                    break;
                case 'VEHICLE_CALLBACK':
                    if (this.allow('PLUS')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'VIN':
                    if (this.allow('PLUS')) return true;
                    if (this.accountData.role === RolesEnum.ROLE_GLOBAL_ADMIN) return true;
                    break;
                case 'SELLIN':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.ROLE_SELLIN) > -1) return true;
                    break;
                case 'BELONGING_WORK':
                    if (this.allow('PLUS')) return true;
                    break;
                case 'SUPPLIER_MANAGEMENT':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.SUPPLIER_MANAGEMENT) > -1) return true;
                    break;
                case 'USER_MANAGEMENT':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.USER_MANAGEMENT) > -1) return true;
                    break;
                case 'SETTINGS_MANAGEMENT':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.SETTINGS_MANAGEMENT) > -1) return true;
                    break;
                case 'ASSORTMENT_PRIORITISATION':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.ASSORTMENT_PRIORITISATION) > -1) return true;
                    break;
                case 'ORDER_HISTORY':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.ORDER_HISTORY) > -1) return true;
                    break;
                case 'CHANGE_EMAIL':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.CHANGE_EMAIL) > -1) return true;
                    break;
                case 'CHANGE_PASSWORD':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.CHANGE_PASSWORD) > -1) return true;
                    break;
                case 'FIRM_STATISTIC':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.FIRM_STATISTIC) > -1) return true;
                    break;
                case 'AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE':
                    if (this.accountData.privileges.indexOf(PrivilegesEnum.AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE) > -1) return true;
                    break;
            }
        }
        return false;
    }

    public getSuppliersList(): Observable<any> {
        return this.http.get<any>(
            `${environment.apiUrl}/session/suppliers`
        );
    }

    public getLocales(clubId?: number): Observable<any> {
        if (clubId) {
            return this.http.get<any>(
                `${environment.apiUrl}/session/languages?clubId=${clubId}`
            );
        } else {
            return this.http.get<any>(
                `${environment.apiUrl}/session/languages`
            );
        }
    }


    public getCountries(clubId?: number): Observable<any> {
        if (clubId) {
            return this.http.get<any>(
                `${environment.apiUrl}/session/countries?clubId=${clubId}`
            );
        } else {
            return this.http.get<any>(
                `${environment.apiUrl}/session/countries`
            );
        }
    }

    public getMileageUnit() {
        if (this.accountData && this.accountData.country) {
            if (this.accountData.country === 'GB') {
                return 'MAINTENANCE.MILES'
            } else {
                return 'MAINTENANCE.KILOMETRE'
            }
        }
    }

    public getDefaultCountry(clubId: number): Observable<any> {
        if (clubId) {
            return this.http.get<any>(
                `${environment.apiUrl}/admin/country/default/${clubId}`
            );
        }
    }


    public getFirmSettings() {
        return this.http.get<any>(
            `${environment.apiUrl}/session/firm/settings`
        );
    }

    public getExternalTools() {
        this.getExternalToolsRest().subscribe(
            (externalTools: IPossibleExternalTool[]) => {
                this.activeExternalTools = externalTools;
            }, (error) => {
                // cant change to helperService.showNotification() cause of circular dependency
                this.translate.get('TOAST_MESSAGES.ERROR_GET').subscribe((msg) => {
                    this.toastrService.error(msg, '');
                })
            });
    }

    public getExternalToolsRest() {
        return this.http.get<any>(
            `${environment.apiUrl}/session/externaltools`
        );
    }

    public setUsageRest(usage: string): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/usage?type=${usage}`,
            {}
        );
    }
}
