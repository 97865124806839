<div class="heading blue">
    <span class="clickable">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
</div>

<div class="panel-content center-content white-background">
    <div class="assembly-categories">
        <div class="assembly-category" routerLink="/company-admin/firm">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.FIRMDATA' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/profile" *ngIf="userService.allow('CHANGE_PASSWORD')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.MY_PROFILE' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/email" *ngIf="userService.allow('CHANGE_EMAIL')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.MY_EMAIL' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/notifications">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.MY_NOTIFICATIONS' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/user" *ngIf="userService.allow('USER_MANAGEMENT')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.USER_MANAGEMENTS' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/suppliers" *ngIf="userService.allow('SUPPLIER_MANAGEMENT')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/tools" *ngIf="userService.allow('SETTINGS_MANAGEMENT')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/settings" *ngIf="userService.allow('SETTINGS_MANAGEMENT')">
            <div class="name-wrapper-no-image">
                {{'USER_PROFILE.SETTINGS' | translate}}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/assortment" *ngIf="userService.allow('ASSORTMENT_PRIORITISATION') && userService.getActiveForName('EXTENDED_SETTINGS','FIRM_ASSORTMENT_PRIORITIZATION')" [ngClass]="{'tile-disabled': userService.getActiveForName('EXTENDED_SETTINGS','AUTOMATIC_ASSORTMENT_PRIORITIZATION')}">
            <div class="name-wrapper-no-image">
                {{ 'CLUB_ADMIN.ASSORTMENT' | translate }}
            </div>
        </div>
        <div class="assembly-category" routerLink="/company-admin/orderhistory" *ngIf="userService.allow('ORDER_HISTORY')">
            <div class="name-wrapper-no-image">
                {{'ADMIN_PANEL.ORDER_HISTORY' | translate}}
            </div>
        </div>
    </div>
</div>
