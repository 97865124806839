import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {IFirm} from '../interfaces/DTO/firm/firm';
import {ILogin} from '../interfaces/DTO/firm/login';
import {IExternalFirmTools} from '../interfaces/DTO/firm/external-tools';
import {IPossibleExternalTool} from '../interfaces/possible-external-tool';
import {IAccountData} from "./user/interfaces/account-data";

@Injectable()
export class FirmAdminRestService {

    constructor(private http: HttpClient) {
    }

    public getFirm(): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/firmadmin/firm`
        );
    }

    public getUserByID(userID: number): Observable<ILogin> {
        return this.http.get<ILogin>(
            `${environment.apiUrl}/firmadmin/login/${userID}`
        );
    }

    public setUserByID(userData: IAccountData, userID: number): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/firmadmin/login/${userID}`,
            userData
        );
    }

    public createUser(userData: IAccountData): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/firmadmin/login/create`, userData
        );
    }

    public updateFirm(firmData: IFirm): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/firmadmin/firm`,
            firmData
        );
    }

    public getExternalTools(): Observable<IExternalFirmTools[]> {
        return this.http.get<IExternalFirmTools[]>(
            `${environment.apiUrl}/firmadmin/externaltools`
        );
    }

    public deleteExternalTool(toolId: number) {
        return this.http.delete(
            `${environment.apiUrl}/firmadmin/externaltools/${toolId}`
        );
    }

    public getPossibleExternalTools(): Observable<IPossibleExternalTool[]> {
        return this.http.get<IPossibleExternalTool[]>(
            `${environment.apiUrl}/firmadmin/unassignedexternaltools`
        );
    }

    public updateExternalTool(tool: IExternalFirmTools): Observable<IExternalFirmTools> {
        return this.http.put<IExternalFirmTools>(
            `${environment.apiUrl}/firmadmin/externaltools`,
            tool
        );
    }

    public createExternalTool(tool: IExternalFirmTools): Observable<IExternalFirmTools> {
        return this.http.post<IExternalFirmTools>(
            `${environment.apiUrl}/firmadmin/externaltools`,
            tool
        );
    }
}
